<template>
  <!--begin::Dashboard-->
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <!--
          <div class="col-xl-4">
      <div class="card mb-5 mb-xl-8">
        <div class="card-body">

<div class="fw-bold fs-6">Hoşgeldiniz,</div>
<div class="fw-bolder fs-1">Buse Çallı! </div>
            <p class="fw-bolder fs-4 mb-1">Buse Çallı,           
  <a class="fw-bold fs-6" style="color:black">hoşgeldiniz!</a>
</p>
</div>
</div>
<div class="card mb-5 mb-xl-8">
<div class="card-body">
<span class="svg-icon svg-icon-3x mx-n1">
  <inline-svg src="media/icons/duotune/graphs/gra007.svg" />
</span>

<div class="fw-bolder fs-1 mb-2 mt-5">495</div>

<div class="fw-bold fs-7">Toplam Kayıt Sayısı</div>
<div>
<apexchart height=260px type="donut" :options="options" :series="series"></apexchart>
</div>
</div>
</div>
</div>
    -->
    <div class="col-xl-12">
      <div class="card mb-xl-8">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">{{
              $t("dashboard.chart.title")
            }}</span>

            <span class="text-muted fw-bold fs-7">{{
              $t("dashboard.chart.desc")
            }}</span>
          </h3>
        </div>
        <!--end::Header-->

        <!--begin::Body-->
        <div class="card-body">
          <!--
           <div class="text-center">

            <div class="card-label fw-bolder fs-3">Toplam İşlem Sayısı</div>
            <span class="text-muted fw-bold fs-7"
              >Son 7 günlük toplam işlem grafiği</span
            >
          </div>
          -->
          <!--begin::Chart-->
          <apexchart
            height="400px"
            type="area"
            :options="options[0]"
            :series="series"
          ></apexchart>
          <!--end::Chart-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-3">
      <!--begin::Statistics Widget 5-->
      <a
        class="card card-xl-stretch mb-xl-8"
        style="background-color: forestgreen"
      >
        <!--begin::Body-->
        <div class="card-body">
          <span class="svg-icon svg-icon-3x mx-n1 svg-icon-white">
            <inline-svg src="media/icons/duotune/graphs/gra007.svg" />
          </span>

          <div class="text-inverse-primary fw-bolder fs-1 mb-2 mt-5">{{statistics.totalTransactionCount}}</div>

          <div class="text-inverse-primary fw-bold fs-7">
            {{ $t("dashboard.sections.totalProcessCount") }}
          </div>
        </div>
        <!--end::Body-->
      </a>
      <!--end::Statistics Widget 5-->
    </div>

    <div class="col-xl-3">
      <!--begin::Statistics Widget 5-->
      <a class="card card-xl-stretch mb-xl-8" style="background-color: brown">
        <!--begin::Body-->
        <div class="card-body">
          <span class="svg-icon svg-icon-3x mx-n1 svg-icon-white">
            <inline-svg src="media/icons/duotune/graphs/gra007.svg" />
          </span>

          <div class="text-inverse-warning fw-bolder fs-1 mb-2 mt-5">{{statistics.activeModuleCount}}</div>

          <div class="text-inverse-warning fw-bold fs-7">
            {{ $t("dashboard.sections.modalCount") }}
          </div>
        </div>
        <!--end::Body-->
      </a>
      <!--end::Statistics Widget 5-->
    </div>

    <div class="col-xl-3">
      <!--begin::Statistics Widget 5-->
      <a class="card card-xl-stretch mb-xl-8" style="background-color: coral">
        <!--begin::Body-->
        <div class="card-body">
          <span class="svg-icon svg-icon-3x mx-n1 svg-icon-white">
            <inline-svg src="media/icons/duotune/graphs/gra007.svg" />
          </span>

          <div class="text-inverse-info fw-bolder fs-1 mb-2 mt-5">{{statistics.tenantUserCount}}</div>

          <div class="text-inverse-info fw-bold fs-7">
            {{ $t("dashboard.sections.staffCount") }}
          </div>
        </div>
        <!--end::Body-->
      </a>
      <!--end::Statistics Widget 5-->
    </div>

    <div class="col-xl-3">
      <!--begin::Statistics Widget 5-->
      <a class="card card-xl-stretch mb-xl-8 bg-info">
        <!--begin::Body-->
        <div class="card-body">
          <span class="svg-icon svg-icon-3x mx-n1 svg-icon-white">
            <inline-svg src="media/icons/duotune/graphs/gra007.svg" />
          </span>

          <div class="text-inverse-info fw-bolder fs-1 mb-2 mt-5">{{statistics.todayTransactionCount}}</div>

          <div class="text-inverse-info fw-bold fs-7">
            {{ $t("dashboard.sections.todaysprocessCount") }}
          </div>
        </div>
        <!--end::Body-->
      </a>
      <!--end::Statistics Widget 5-->
    </div>
  </div>
  <!--end::Row-->

  <!--end::Dashboard-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { getCSSVariableValue } from "../assets/ts/_utils";
import { useI18n } from "vue-i18n";
import store from "@/presentation/store";
import SwalNotification from "../plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { StatisticModel } from "@/domain/statistic/model/StatisticModel";

export default defineComponent({
  name: "dashboard",
  components: {},
  setup() {
    const { t } = useI18n();
    const statisticController = store.state.ControllersModule.statisticController;
    const swalNotification = new SwalNotification();

    const statistics = ref<StatisticModel>({});

    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-info");
    const lightColor = getCSSVariableValue("--bs-light-info");

    const options = ref<any>([
      {
      chart: {
        fontFamily: "inherit",
        type: "area",
        height: 50,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        categories: [
          new Date().toLocaleDateString()
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return +val + " işlem";
          },
        },
      },
      colors: [lightColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColor: baseColor,
        strokeWidth: 3,
      }
    }
      ]);

    const series = ref([
      {
        name: "İşlem Sayısı",
        data: [0],
      },
      ]);

    const getStatistics = async () => {
      statisticController
        .getStatistic()
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(statistics.value, response.getValue());
            
            if(statistics.value.weeklyTransactionSummary)
            statistics.value.weeklyTransactionSummary.forEach((transactionSummary) => {

              series.value[0].data.push(Number(transactionSummary.transactionCount));

              const newDate = new Date(
                transactionSummary.date
              ).toLocaleDateString("tr-TR");
              options.value[0].xaxis.categories.push(newDate);
            });
            options.value[0].xaxis.categories.reverse();
            series.value[0].data.reverse();

            options.value[0].xaxis.categories.pop();
            series.value[0].data.pop()

          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("dashboard.title"));
      getStatistics();
    });

    return {
      options,
      series,
      statistics,
    };
  },
});
</script>
